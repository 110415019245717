@property --gradient-angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@font-face {
  font-family: 'TestTheFuture';
  src: url('/fonts/test-the-future-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TestTheFuture';
  src: url('/fonts/test-the-future-medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TestTheFutureMono';
  src: url('/fonts/test-the-future-mono-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UKNumberPlate';
  src: url('/fonts/UKNumberPlate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* fonts */
  --medium-7: 'Plus Jakarta Sans';
  /*--medium-7: 'TestTheFuture';*/

  /* font sizes */
  --medium-7-size: 1.33rem;
  --medium-8-size: 1.4rem;
  --regular-6-size: 1.67rem;
  --medium-4-size: 2.67rem;
  --medium-5-size: 2rem;
  --medium-2-size: 4rem;
  --medium-3-size: 3.33rem;
  --medium-1-size: 5.33rem;

  /* Font sizes v2 (Needs to be implement) */
  /*--font-size-base: 1rem; !* Base font size for normal text (e.g., paragraphs, body text) *!
  --font-size-lg: 1.618rem; !* Larger text, suitable for subheadings or h5 *!
  --font-size-xl: 2.618rem; !* Extra-large text, ideal for medium-sized headings like h2 or h3 *!
  --font-size-xxl: 4.236rem; !* Largest text, perfect for main headings like h1 *!
  --font-size-sm: 0.618rem; !* Small text, appropriate for secondary content or captions *!
  --font-size-xs: 0.382rem; !* Extra-small text, useful for metadata or very fine details *!*/

  --font-size-base: 12px;
  --font-size-xxs: 8px;
  --font-size-base-alt: 14px;
  --font-size-xs: 16px;
  --font-size-sm: 20px;
  --font-size-md: 24px;
  --font-size-lg: 32px;
  --font-size-xl: 40px;
  --font-size-xxl: 48px;
  --font-size-2xl: 52px;
  --font-size-3xl: 60px;

  /* Colors */
  --secondary: #05293d;
  --primary: #17e8da;
  --blue: #4c78ec;
  --white: #fff;
  --back-light: #f7f6f9;
  --grey-1: #edf1f3;
  --grey-4: #727c82;
  --color-gray-100: #252525;
  --black: #121213;
  --color-black: #000;
  --color-gainsboro: #d4e0e6;
  --color-text-light: #475963;
  --linear-gradient: linear-gradient(
    60deg,
    var(--purple-color),
    var(--primary-color),
    var(--green-1-color),
    var(--yellow-color)
  );

  /* Gaps */
  --gap-5xl: 2rem;
  --gap-29xl: 4rem;
  --gap-base: 1.33rem;
  --gap-3xs: 0.83rem;
  --gap-5xs: 0.67rem;
  --gap-13xl: 2.67rem;
  --gap-21xl: 3.33rem;
  --gap-37xl: 4.67rem;
  --gap-61xl: 6.67rem;
  --gap-53xl: 6rem;

  --base-size-16: 1rem;
  --base-size-24: 1.5rem;
  --base-size-32: 2rem;
  --base-size-40: 2.5rem;
  --base-size-48: 3rem;
  --base-size-56: 3.5rem;
  --base-size-64: 4rem;
  --base-size-72: 4.5rem;
  --base-size-80: 5rem;
  --base-size-88: 5.5rem;
  --base-size-96: 6rem;

  /* Paddings */
  --padding-5xl: 2rem;
  --padding-26xl: 3.75rem;
  --padding-45xl: 5.33rem;
  --padding-13xl: 2.67rem;
  --padding-77xl: 8rem;
  --padding-5xs: 0.67rem;
  --padding-base: 1.33rem;
  --padding-3xs: 0.83rem;
  --padding-21xl: 3.33rem;
  --padding-61xl: 6.67rem;
  --padding-xl: 1.67rem;

  --padding-xs: 2px;
  --padding-sm: 4px;
  --padding-md: 8px;
  --padding-lg: 16px;
  --padding-xl: 24px;

  /* Spacing */
  --spacing-xs: 2px;
  --spacing-sm: 4px;
  --spacing-md: 8px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  --spacing-xxl: 32px;
  --spacing-2xl: 56px;
  --spacing-3xl: 72px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-base: 16px;
  --br-81xl: 100px;

  /* Border radiuses V2 */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;
  --border-radius-xl: 24px;
  --border-radius-xxl: 32px;
  --border-radius-xxxl: 160px;

  /*--max-width: 1440px;*/
  /*--max-width: 1376px;*/
  /*--max-width: 1324px;*/
  --max-width: 1280px;
  --border-radius: 8px;
  --font-mono: 'IBM Plex Mono', monospace;
  --font-plate: 'UKNumberPlate', monospace;
  /*--font-mono: 'TestTheFutureMono', monospace;*/

  --line-height-normal: 2.89rem;

  --font-primary: 'Plus Jakarta Sans', sans-serif;
  /*--font-primary: 'TestTheFuture', sans-serif;*/
  --primary-color: #17e5d7;
  --secondary-color: #05293d;
  --white-color: #ffffff;
  --black-color: #121213;
  --green-1-color: #40fb76;
  --green-2-color: #29ad71;
  --green-3-color: #0d6225;
  --magenta-color: #d050e9;
  --orange-color: #fa9751;
  --purple-color: #4c78e9;
  --purple-2-color: #7f9ff3;
  --blue-color: #4eb4d5;
  --coral-color: #ff6666;
  --back-light-color: #f7f6f9;
  --red-color: #ff2450;
  --yellow-color: #fff932;
  --grey-4-color: #727c82;
  --grey-3-color: #b6c2ca;
  --grey-2-color: #d4e0e6;
  --grey-1-color: #edf1f3;
  --dark-yellow-color: #695100;
  --transparent-black: rgb(0, 0, 0, 0.25);
  --transparent-white: rgb(255, 255, 255, 0.35);
  --transparent-white-alt: rgb(255, 255, 255, 0.55);
  --transparent: rgb(0, 0, 0, 0);
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --rpv-core__inner-page-background-color: transparent !important;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 5, 41, 61;
    --background-end-rgb: 5, 41, 61;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 9px;
}

body {
  margin: 0;
  /*line-height: normal;*/
  line-height: var(--line-height-normal);
}

body.modalOpen {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

:focus-visible {
  outline-color: var(--primary-color);
}

input::placeholder {
  color: var(--grey-4-color);
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;
  /*border-left: 1px solid var(--grey-2-color);
  border-bottom: 1px solid var(--grey-2-color);*/
}

.StripeElement--webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  color: var(--white-color) !important;
}

/* Table header styles */
.table thead th {
  background-color: var(--secondary-color);
  color: var(--white-color);
  padding: 2px;
  text-align: left;
}

/* Table body styles */
.table tbody td {
  padding: 2px;
}

/* Alternate row background colors */
.table tbody tr:nth-child(even) {
  background-color: var(--grey-1-color); /* grey background */
}

.table tbody tr:nth-child(odd) {
  background-color: var(--white-color); /* white background */
}

.table img {
  height: 16px;
  padding-right: 20px;
}

/* Label styles */
.label {
  font-weight: normal;
}

/* Value styles */
.value {
  /* margin-right: 5px;*/
  font-weight: bold;
}

/* Unit styles */
.unit {
  margin-left: 4px;
  margin-right: 8px;
  font-style: italic;
}

.detailSubtitle {
  font-size: 1.7rem;
  font-weight: 600;
  font-family: var(--font-primary);
  padding: 0 0 1rem 0;
}

.contentImage {
  max-width: 100%;
  border-radius: var(--border-radius);
}

/* Change the color of the progress bar */
#nprogress .bar {
  background: var(--primary-color) !important;
}

/* Change the color of the spinner */
#nprogress .spinner-icon {
  border-top-color: var(--primary-color) !important;
  border-left-color: var(--primary-color) !important;
}

.react-share__ShareButton svg circle {
  fill: var(--secondary-color);
  transition: all 0.3s ease;
}

.react-share__ShareButton svg path {
  fill: var(--white-color);
  transition: all 0.3s ease;
}

.react-share__ShareButton:hover svg circle {
  fill: var(--primary-color);
}

.react-share__ShareButton:hover svg path {
  fill: var(--secondary-color);
}

svg,
g {
  transform-origin: unset unset;
}

polygon,
polyline,
.originCenter {
  transform-origin: 50% 50%;
  transform-box: fill-box;
}

.transformOrigin {
  transform-origin: center;
}

.opacity0 {
  opacity: 0;
}

.ribbonLink {
  color: var(--primary-color);
}

.transition-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1001;
  overflow: hidden;
}

.skew-div {
  width: 100%;
  height: 100%;
  /* background-color: var(--secondary-color);*/
  background-image: radial-gradient(#0e3346 20%, var(--secondary) 20%);
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 10px 10px;
  transform: skewX(-10deg);
}

.logo {
  position: fixed; /* Changed from 'absolute' */
  top: 50%;
  left: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  font-family: var(--font-mono);
  letter-spacing: 0.2rem;
  /*animation: blink 0.4s ease infinite;*/
  animation: blink 0.4s ease-in-out infinite;
  z-index: 2000;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;
}

.buttonAction {
  padding: 12px 16px;
  justify-content: center;
  border-radius: 50px;
  color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: var(--white-color);
  align-items: center;
  gap: 8px;
  font-family: var(--font-mono);
  text-transform: uppercase;
  box-shadow: -4px 4px 0px 0px var(--secondary-color);
  border: 1px solid var(--secondary-color);
  font-size: 1.4rem;
  transition: all 0.2s ease-in-out;

  min-width: 160px;

  font-weight: 300;
  z-index: 100;
}

.buttonAction .icon {
  position: relative;
  width: 2rem;
  height: 1.12rem;
  flex-shrink: 0;
  z-index: 100;
  display: inline;
  fill: var(--secondary-color);
}

.buttonAction .label {
  position: relative;
  font-weight: 300;
  z-index: 100;
}

.buttonAction:hover {
  gap: 16px;
  box-shadow: -0px 0px 0px 0px var(--secondary-color);
  transform: translate(-4px, 4px);
}

.buttonActionPrimary {
  padding: 12px 16px;
  justify-content: center;
  border-radius: 50px;
  color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: var(--primary-color);
  align-items: center;
  gap: 8px;
  font-family: var(--font-mono);
  text-transform: uppercase;
  box-shadow: -4px 4px 0px 0px var(--secondary-color);
  border: 1px solid var(--secondary-color);
  font-size: 1.4rem;
  transition: all 0.2s ease-in-out;

  min-width: 160px;

  font-weight: 300;
  z-index: 100;
}

.caption {
  font-family: var(--font-mono);
  font-size: 1.4rem;
  color: var(--secondary-color);
  opacity: 0.9;
}

.buttonActionPrimary .icon {
  position: relative;
  width: 2rem;
  height: 1.12rem;
  flex-shrink: 0;
  z-index: 100;
  display: inline;
  fill: var(--secondary-color);
}

.buttonActionPrimary .label {
  position: relative;
  font-weight: 300;
  z-index: 100;
}

.buttonActionPrimary:hover {
  gap: 16px;
  box-shadow: -0px 0px 0px 0px var(--secondary-color);
  transform: translate(-4px, 4px);
}

/* General styles for unordered lists (bulleted lists) */
.bulletList {
  list-style-type: disc; /* Standard disc-style bullets */
  padding-left: 20px; /* Indent items to distinguish the list from surrounding text */
  margin-top: 0; /* Remove top margin for consistent spacing */
  margin-bottom: 1em; /* Bottom margin for spacing below the list */
}

.bulletList li {
  line-height: 1.6; /* Improve readability of list items */
  margin-bottom: 0.5em; /* Space between individual list items */
}

/* General styles for ordered lists (numbered lists) */
.numberedList {
  list-style-type: decimal; /* Numbers for each list item (1, 2, 3, ...) */
  padding-left: 20px; /* Indent items */
  margin-top: 0; /* Consistent top spacing */
  margin-bottom: 1em; /* Spacing below the list */
}

.numberedList li {
  line-height: 1.6; /* Readability of list items */
  margin-bottom: 0.5em; /* Space between items */
}

/* Styles for lettered lists, using lower-alpha for lowercase a, b, c, ... */
.letteredList {
  list-style-type: lower-alpha; /* Alphabetical markers */
  padding-left: 20px; /* Indentation */
  margin-top: 0; /* Top margin consistency */
  margin-bottom: 1em; /* Bottom margin */
}

.letteredList li {
  line-height: 1.6; /* Line height for readability */
  margin-bottom: 0.5em; /* Space between list items */
}

canvas {
  image-rendering: optimizeQuality;
}

/*.rpv-core__inner-pages,*/
/*.rpv-core__inner-pages--vertical {*/
/*  overflow-x: hidden !important;*/
/*}*/

div.rpv-core__viewer {
  min-height: 600px;
}

.rpv-toolbar__left {
  display: none !important;
}

.rpv-toolbar__center {
  justify-content: flex-start !important;
}

/* customToolbarStyles.css */
.rpv-toolbar {
  padding-bottom: 10px;
  font-family: 'IBM Plex Mono', monospace;
}

.rpv-core__minimal-button {
  color: #9fcaf1 !important;
}

.rpv-zoom__popover-target-arrow {
  border-color: #9fcaf1 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
}

.rpv-toolbar__button:hover {
  background-color: #45a049; /* Change hover color */
}

/* Control (the main container) */
.select__control {
  appearance: none !important;
  white-space: nowrap !important;
  font-weight: normal !important;
  font-size: 1.6rem !important;
  line-height: 20px !important;
  border: 1px solid var(--grey-2-color) !important;
  color: var(--secondary-color) !important;
  outline: none !important;
  z-index: 1 !important;
  vertical-align: middle !important;
  word-break: normal !important;
  transition: all ease-in-out 0.3s !important;
  user-select: auto !important;
  background: transparent !important;
  flex: 0 auto !important;
  margin: 0 !important;
  text-decoration: none;
  position: relative;
  min-height: 36px !important;
  padding: 0 8px !important; /* Adjusted padding */
  cursor: text !important;
  border-radius: var(--border-radius) !important;
  font-family: var(--font-primary) !important;
}

.select__control--is-focused {
  border: 1px solid var(--primary-color) !important;
}

.select__control--hover {
  border: 1px solid var(--primary-color) !important;
}

/* Placeholder text */
.select__placeholder {
  font-family: var(--font-primary);
  color: var(--grey-3-color) !important;
}

/* Single Value (selected option in single-select) */
.select__single-value {
  color: var(--secondary-color);
}

/* Multi Value (selected options in multi-select) */
.select__multi-value {
  background-color: var(--grey-2-color);
  border-radius: var(--border-radius);
  margin: 2px;
}

/* Multi Value Label */
.select__multi-value__label {
  color: var(--secondary-color);
  padding: 4px;
}

/* Multi Value Remove Button */
.select__multi-value__remove {
  color: var(--secondary-color);
  cursor: pointer;
  padding: 4px;
}

/* Indicators (dropdown arrow, clear indicator) */
.select__indicator {
  color: var(--secondary-color);
  padding: 8px;
}

/* Menu (dropdown list) */
.select__menu {
  border: 1px solid var(--grey-2-color);
  border-radius: var(--border-radius);
  margin-top: 4px;
  z-index: 1000 !important;
}

/* Option (individual option in the dropdown list) */
.select__option {
  padding: 8px 16px;
  color: var(--secondary-color);
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: 14px !important;
}

/* Option when hovered */
.select__option--is-focused {
  background-color: var(--grey-1-color);
}

/* Option when selected */
.select__option--is-selected {
  background-color: var(--primary-color);
  color: #fff;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.recharts-tooltip-cursor {
  fill: transparent !important;
}

/* Mobile */
@media (max-width: 700px) {
  html {
    font-size: 8px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

::selection {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}
