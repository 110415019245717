.smallLogoIcon {
  position: relative;
  width: 5rem;
  height: 2.5rem;
  flex-shrink: 0;
}
.footer1Left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-45xl) 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.title {
  align-self: stretch;
  position: relative;
  line-height: 3.33rem;
  font-weight: 600;
}
.description {
  position: relative;
  font-size: var(--regular-6-size);
  line-height: var(--line-height-normal);
  color: var(--back-light);
}
.button,
.description,
.header {
  align-self: stretch;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.button {
  position: relative;
  line-height: 2rem;
}
.westminsterBridgeRd {
  margin: 0;
  line-height: 2.33rem;
}
.buttons,
.footer1Right1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttons {
  align-self: stretch;
  gap: var(--gap-5xl);
  font-size: var(--regular-6-size);
}
.footer1Right1 {
  flex: 1;
  padding: var(--padding-45xl) var(--padding-13xl);
  gap: var(--gap-29xl);
  border-bottom: 0px solid var(--primary);
}
.description1 {
  align-self: stretch;
  position: relative;
  font-size: var(--regular-6-size);
  line-height: var(--line-height-normal);
  color: var(--white);
}
.button3 {
  position: relative;
  /*border-radius: var(--br-5xs);
  background-color: var(--primary);
  width: 2.67rem;
  height: 2.67rem;*/
  flex-shrink: 0;
  font-size: var(--regular-6-size);
}
.buttons1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.footer1Content,
.footer1Right,
.footer1Right2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer1Right2 {
  align-self: stretch;
  flex: 1;
  border-left: 1px solid var(--primary);
  border-bottom: 0 solid var(--primary);
  flex-direction: column;
  padding: var(--padding-45xl) var(--padding-13xl);
  gap: var(--gap-29xl);
}
.footer1Content,
.footer1Right {
  flex-direction: row;
}
.footer1Right {
  flex: 1;
  border-left: 1px solid var(--primary);
  border-bottom: 0px solid var(--primary);
}
.footer1Content {
  align-self: stretch;

  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-width: var(--max-width);
}
.line {
  align-self: stretch;
  position: relative;
  height: 0;
  flex-shrink: 0;
  border-bottom: 1px solid var(--transparent-white);
}
.footer1,
.lineContent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer1 {
  /*background-color: var(--secondary);*/

 /* background-image: radial-gradient(#0e3346 20%, var(--secondary) 20%);
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 10px 10px;*/

  padding: 0 var(--padding-26xl);
  text-align: left;
  font-size: var(--medium-4-size);
  color: var(--primary);
  font-family: var(--medium-7);

  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.footer1Left,
.footer1Right,
.footer1Right1,
.footer1Right2 {
  width: 100%;
}

a.button3,
a.button {
  color: inherit;
  text-decoration: none;
}

a.button3:hover,
a.button:hover {
  text-decoration: underline;
}

.description,
.description1 {
  opacity: 0.8;
}

/* Mobile */
@media (max-width: 700px) {
  .footer1 {
    padding: 0 var(--padding-5xl);
  }

  .footer1Content,
  .footer1Right {
    flex-direction: column;
  }

  .footer1Right,
  .footer1Right2 {
    border-left: 0 solid var(--primary);
  }

  .footer1Right1,
  .footer1Right2 {
    padding: var(--padding-45xl) 0;
    border-bottom: 1px solid var(--primary);
  }

  .description,
  .description1 {
    font-size: var(--medium-5-size);
  }

  .buttons {
    gap: var(--gap-13xl);
  }

  a.button {
    font-size: var(--medium-5-size);
  }

  a.button:hover {
    font-size: var(--medium-5-size);
  }

  .button {
    font-size: var(--medium-5-size);
  }

  .button3 {
    font-size: var(--medium-5-size);
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}
