.ribbon {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 10px 20px;
  font-size: var(--medium-8-size);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-mono);
  align-items: center;
  z-index: 1000;
}

.closeButton {
  background-color: transparent;
  border: none;
  color: var(--secondary-color);
  font-size: var(--medium-3-size);
  cursor: pointer;
  font-family: var(--font-mono);
}

.closeButton:hover {
  color: var(--secondary-color);
}
